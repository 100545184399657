<template>
    <div>
      <h4>Type de produit</h4>
      
      <data-table
        :data-source="types"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-type-product"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
      <modal
        title="Ajout du type de produit"
        id="add-type-product"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="addingTypeProduct"
        >
          <div class="row">
            <div class="col-8">
                <div class="form-group">
                    <label for="add-libelle">Libelle</label>
                    <input
                        type="text"
                        id="add-libelle"
                        name="add-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="add-code">Code</label>
                    <input
                        type="text"
                        id="add-code"
                        name="add-code"
                        v-model="code"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-description">Description</label>
                    <textarea
                        type="text"
                        id="add-description"
                        name="add-description"
                        v-model="description"
                        class="form-control"
                    ></textarea>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </modal>
      <modal
        title="Modification du type de produit"
        id="update-type-product"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editingTypeProduct"
        >
          <div class="row">
            <div class="col-8">
                <div class="form-group">
                    <label for="edit-libelle">Libelle</label>
                    <input
                        type="text"
                        id="edit-libelle"
                        name="edit-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label for="edit-code">Code</label>
                    <input
                        type="text"
                        id="edit-code"
                        name="edit-code"
                        v-model="code"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-description">Description</label>
                    <textarea
                        type="text"
                        id="edit-description"
                        name="edit-description"
                        v-model="description"
                        class="form-control"
                    ></textarea>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import {TEXT_TYPE, COMPONENT_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import { ADD_TYPE_PRODUCT, UPDATE_TYPE_PRODUCT } from '../../../graphql/market'
  const Actions = () => import('../../../components/admin/market/typeProductActions.vue')
  export default {
      components: { DataTable, Modal},
      data(){
          return {
              libelle: null,
              code: null,
              description: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.code = this.selectedObject.code
                      this.description = this.selectedObject.description
                  }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.code = null
              this.description = null
          },
          addingTypeProduct(){
              let data = {
                  libelle: this.libelle,
                  code: this.code,
                  description: this.description
              }
              this.$apollo.mutate({
                  mutation: ADD_TYPE_PRODUCT,
                  variables: {
                      "type": {
                          ...data
                      }
                  },
                  update: ({data}) => {
                      console.log(`Alimentation add successfully on uid ${data.addMarketTypeObject}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editingTypeProduct(){
              let data = {
                  libelle: this.libelle,
                  code: this.code,
                  description: this.description
              }
              this.$apollo.mutate({
                  mutation: UPDATE_TYPE_PRODUCT,
                  variables: {
                      "type": {
                          ...data
                      }, 
                      "uid": this.selectedObject.uid
                  },
                  update: () => {
                      console.log(`Type product  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              types: 'market/typeProducts',
              selectedObject: 'selectedObject'
          }),
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Code', name: 'code', type: TEXT_TYPE},
                  {label: 'Description', name: 'description', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>